import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { datagridSettingsActions } from '../../../../../store/datagrids-settings/datagrids-settings.reducers';
import { validationDatagridStateSelector } from '../../../../../store/datagrids-settings/datagrids-settings.selectors';
import { useGetCommonTypesQuery } from '../../../../../services/common-types-api.services';
import { FilterReducer, LoadDataProps } from '../../../../../models/datagrid';
import {
  useLazyGetOrdersQuery,
  usePatchOrderMutation
} from '../../../../../services/orders-api.services';
import { Button, SideBarModal } from '@platform-storybook/circlestorybook';
import DatagridFeature from '../../../../../features/datagrid/DatagridFeature';
import styles from '../../production-page.module.scss';
import {
  ActionBtn,
  buildOrdersFilters,
  buildSort,
  rowClassname
} from '../../../../../features/datagrid/columns';
import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { Order } from '../../../../../models/order';
import { getDatagridColumns } from './validation';
import { WorkflowStepEnum } from '../../../../../enum/workflow-step';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../../enum/feedback';
import Comments from '../../../../../features/comments/Comments';
import DatagridReload from '../../../../../features/datagrid/DatagridReload';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { getValidationTabPermissionsSelector } from '../../../../../store/auth/permissions.selectors.tsx';

const ValidationPage = () => {
  const { t } = useTranslation(['production']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const connectedUserPermissions = useAppSelector(getValidationTabPermissionsSelector);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [displayCommentsForm, setDisplayCommentsForm] = useState<boolean>(false);
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [reloadDate, setReloadDate] = useState<Date>();
  const datagridSettings = useAppSelector(validationDatagridStateSelector);
  const [getOrders] = useLazyGetOrdersQuery();
  const [patchOrder, { isSuccess: isPatchedOrder }] = usePatchOrderMutation();
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();

  useEffect(() => {
    if (isPatchedOrder) {
      dispatch(
        feedbackActions.setToast({
          message: t(
            selectedOrder?.toManufacture
              ? 'toast.setNotToBeManufactured'
              : 'toast.setToBeManufactured',
            { ns: 'dashboard' }
          ),
          type: ToastType.SUCCESS
        })
      );
    }
  }, [isPatchedOrder]);

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = buildOrdersFilters(filterValue!, WorkflowStepEnum.VALIDATION);
    const sort = buildSort(sortInfo);
    return getOrders({ page, limit, filters, sort })
      .unwrap()
      .then((orders) => {
        setReloadDate(new Date());
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };
  const dataSource = useCallback(loadData, [commonTypes, isPatchedOrder, isReloadNeeded]);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'validation',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    dispatch(
      datagridSettingsActions.setDatagridSort({
        datagrid: 'validation',
        sort: newSortInfo as TypeSingleSortInfo
      })
    );
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetDatagridFilters({ datagrid: 'validation' }));
  };

  const handleToggleManufactureCallback = async (order: Order) => {
    setSelectedOrder(order);
    patchOrder({
      orderNumber: order.orderNumber,
      toManufacture: !order.toManufacture
    });
  };

  const handleClickComment = (order: Order) => {
    setSelectedOrder(order);
    setSideBarOpened(true);
    setDisplayCommentsForm(true);
  };

  const validateActionBtn: ActionBtn<Order> = {
    label: t('datagrid.action.validate', { ns: 'production' }),
    onClick: (data: Order) => {
      navigate(`../order/${data.orderNumber}/detail`);
    }
  };

  const commentActionBtn: ActionBtn<Order> = {
    label: t('comments.comment', { ns: 'comment' }),
    onClick: (data: Order) => {
      handleClickComment(data);
    }
  };

  const handleClickCloseSidebar = () => {
    setSideBarOpened(false);
    setDisplayCommentsForm(false);
  };

  return (
    <>
      <div className={styles['production-page__filters']}>
        <Button
          category="text"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={removeFilters}
        />
        {reloadDate && (
          <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
        )}
      </div>
      {!areCommonTypesLoading && commonTypes && (
        <DatagridFeature
          key="validation"
          style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
          dataSource={dataSource}
          rowClassName={rowClassname}
          filterValue={datagridSettings.filters}
          onFilterValueChange={onFilterValueChange}
          sortInfo={datagridSettings.sort}
          onSortInfoChange={onSortInfoChange}
          columns={getDatagridColumns(
            connectedUserPermissions,
            dispatch,
            commentActionBtn,
            validateActionBtn,
            commonTypes,
            handleToggleManufactureCallback,
            handleClickComment
          )}
        />
      )}
      {displayCommentsForm && selectedOrder?.orderNumber && (
        <SideBarModal
          title={t('comments.title', {
            orderNumber: selectedOrder.orderNumber,
            ns: 'comment'
          })}
          isOpened={sideBarOpened}
          closeOnOutsideClick={true}
          onClose={handleClickCloseSidebar}>
          <Comments
            orderNumber={selectedOrder.orderNumber}
            inSidebar={true}
            onFirstCommentAddedCallback={() => setIsReloadNeeded(true)}
          />
        </SideBarModal>
      )}
    </>
  );
};

export default ValidationPage;
