import React, { useEffect, useState } from 'react';
import styles from './establishment-form.module.scss';
import stylesParent from '../create-dentist-page.module.scss';
import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Fieldset,
  SideBarModal,
  Text
} from '@platform-storybook/circlestorybook';
import { newDentistSelector } from '../../../../../store/users/users.selectors';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { usersActions } from '../../../../../store/users/users.reducer';
import { useTranslation } from 'react-i18next';
import CreateClinicForm from './create-clinic-form/CreateClinicForm';
import { Establishment } from '../../../../../models/establishment';
import {
  clinicListForDropdownSelector,
  clinicListSelector
} from '../../../../../store/establishment/establishment.selectors';
import { establishmentActions } from '../../../../../store/establishment/establishment.reducer';
import {
  useCreateDentistMutation,
  useGetConnectedUserQuery
} from '../../../../../services/users-api.services';
import {
  useCreateClinicMutation,
  useGetAllClinicsQuery
} from '../../../../../services/establishments-api.services';
import { EstablishmentType, NEW_CLINIC_ID } from '../../../../../enum/establishment';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { getMessageError } from '../../../../../utils/utils';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { ToastType } from '../../../../../enum/feedback';

type Props = {
  submitCallback: () => void;
  previousCallback: () => void;
};

const EstablishmentForm = ({ submitCallback, previousCallback }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dentist = useAppSelector(newDentistSelector);
  const clinicList = useAppSelector(clinicListSelector);
  const clinicListForDropdown = useAppSelector(clinicListForDropdownSelector);

  const { data: connectedUser, isLoading: isLoadingConnectedUser } = useGetConnectedUserQuery();
  const [createDentist, { isLoading: isLoadingCreateDentist, isSuccess: isCreatedDentist }] =
    useCreateDentistMutation();
  const [createClinic, { isLoading: isLoadingCreateClinic }] = useCreateClinicMutation();
  const { data: allClinicsResponse, isLoading: isLoadingGetAllClinics } = useGetAllClinicsQuery();

  const [name, setName] = useState<string | undefined>(dentist?.clinic?.name);
  const [address, setAddress] = useState<string | undefined>(dentist?.clinic?.address?.address);
  const [additionalAddress, setAdditionalAddress] = useState<string | undefined>(
    dentist?.clinic?.address?.additionalAddress
  );
  const [zipCode, setZipCode] = useState<string | undefined>(dentist?.clinic?.address?.zipCode);
  const [city, setCity] = useState<string | undefined>(dentist?.clinic?.address?.city);
  const [country, setCountry] = useState<string | undefined>(dentist?.clinic?.address?.country);
  const [region, setRegion] = useState<string | undefined>(dentist?.clinic?.address?.region ?? '');
  const [selectedClinicId, setSelectedClinicId] = useState<number | undefined>(dentist?.clinic?.id);
  const [createdClinicId, setCreatedClinicId] = useState<number>();
  const [sideBarOpened, setSideBarOpened] = useState(false);
  const [isShowClinicMandatoryMessage, setIsShowClinicMandatoryMessage] = useState(false);
  const [isAllowedToOrder, setIsAllowedToOrder] = useState(dentist?.isAllowedToOrder);

  const userLaboratoryId = connectedUser?.laboratory?.id;

  useEffect(() => {
    if (allClinicsResponse && clinicList.length === 0) {
      dispatch(establishmentActions.setClinicList(allClinicsResponse?.data as Establishment[]));
    }
  }, [allClinicsResponse]);

  useEffect(() => {
    if (createdClinicId) {
      // Once the clinic has been created, we can create the dentist
      addNewDentist(createdClinicId);
    }
  }, [createdClinicId]);

  useEffect(() => {
    if (isCreatedDentist) {
      // Once everything has been created, we call the provided submitCallback
      submitCallback();
    }
  }, [isCreatedDentist]);

  const getClinicInfo = (id: number) => {
    return clinicList.find((clinic: Establishment) => clinic.id === id);
  };

  const updateSelectedClinicInfo = (clinic: Establishment | undefined) => {
    setName(clinic?.name || '');
    setAddress(clinic?.address?.address || '');
    setAdditionalAddress(clinic?.address?.additionalAddress || '');
    setZipCode(clinic?.address?.zipCode || '');
    setCity(clinic?.address?.city || '');
    setRegion(clinic?.address?.region || '');
    setAddress(clinic?.address?.address || '');
    setCountry(clinic?.address?.country || '');
  };

  const saveEstablishmentFormInfo = () => {
    const clinic = {
      id: selectedClinicId,
      name: name,
      address: {
        address: address || undefined,
        additionalAddress: additionalAddress || undefined,
        zipCode: zipCode || undefined,
        city: city || undefined,
        country: country || undefined,
        region: region || undefined
      }
    };
    const userData = {
      ...dentist,
      isAllowedToOrder: isAllowedToOrder,
      clinic: clinic,
      laboratory: { id: userLaboratoryId }
    };

    dispatch(usersActions.setNewDentist(userData));
  };

  const showMandatoryMessages = () => {
    setIsShowClinicMandatoryMessage(!selectedClinicId);
  };

  const addNewDentist = async (clinicId: number) => {
    const newUser = {
      ...dentist,
      isAllowedToOrder: isAllowedToOrder,
      clinic: {
        id: clinicId
      },
      laboratory: {
        id: userLaboratoryId
      },
      sendEmailInvite: true
    };
    createDentist(newUser);
  };

  const addNewClinic = async () => {
    const newClinic = {
      name: name,
      type: EstablishmentType.CLINIC,
      address: {
        address: address,
        additionalAddress: additionalAddress || undefined,
        zipCode: zipCode,
        city: city,
        country: country,
        region: region || undefined
      }
    };

    await createClinic(newClinic)
      .unwrap()
      .then((data) => {
        setSelectedClinicId(data.id);
        // This state is used to trigger the dentist creation after a clinic creation, see useEffect related to createdClinicId
        setCreatedClinicId(data.id);
        dispatch(establishmentActions.removeNewClinicInList());
        dispatch(establishmentActions.addClinicToList(data));
      })
      .catch((error) => {
        dispatch(
          feedbackActions.setToast({
            message: getMessageError(error),
            type: ToastType.DANGER
          })
        );
      });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedClinicId) {
      showMandatoryMessages();
      return;
    }
    // When submitting the form, if the user created a new clinic on the fly
    if (selectedClinicId === NEW_CLINIC_ID) {
      // We first create the new clinic, and then
      // in the useeffect related to its success we will create the dentist
      addNewClinic();
    } else {
      // But if the clinic already exist, we only need to create the dentist
      addNewDentist(selectedClinicId);
    }
  };

  const handlePreviousButton = () => {
    saveEstablishmentFormInfo();
    previousCallback();
  };

  const handleCloseSidebar = (establishment: Establishment) => {
    dispatch(establishmentActions.removeNewClinicInList());
    dispatch(establishmentActions.addClinicToList(establishment));
    setSelectedClinicId(establishment.id);
    updateSelectedClinicInfo(establishment);
    setSideBarOpened(false);
  };

  const isNewClinicCreated = () => selectedClinicId === NEW_CLINIC_ID;

  const creationButtonLabel = (): string => {
    if (isNewClinicCreated()) {
      return t('createDentist.establishment.updateClinic', { ns: 'dentist' });
    } else {
      return t('createDentist.establishment.createClinic', { ns: 'dentist' });
    }
  };

  const newClinic = {
    id: NEW_CLINIC_ID,
    name: isNewClinicCreated() ? name : '',
    address: {
      address: isNewClinicCreated() ? address : '',
      additionalAddress: isNewClinicCreated() ? additionalAddress : '',
      zipCode: isNewClinicCreated() ? zipCode : '',
      city: isNewClinicCreated() ? city : '',
      country: isNewClinicCreated() ? country : '',
      region: isNewClinicCreated() ? region : ''
    }
  };

  const onSelectClinic = (value: number) => {
    setSelectedClinicId(value || undefined);
    updateSelectedClinicInfo(value ? getClinicInfo(value) : undefined);
    setIsShowClinicMandatoryMessage(!value);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={stylesParent['create-dentist-page__form']}>
        <Fieldset
          size="m"
          className={stylesParent['create-dentist-page__form__fieldset--full-height']}>
          <div className={styles['establishment-form__content']}>
            <Dropdown
              data-cy="clinicList"
              label={t('createDentist.establishment.clinic', { ns: 'dentist' })}
              data={isLoadingGetAllClinics ? [] : [clinicListForDropdown]}
              value={selectedClinicId}
              placeholder={t('createDentist.choose', { ns: 'dentist' })}
              onChange={(newValue: number) => onSelectClinic(+newValue)}
              helperText={
                isShowClinicMandatoryMessage
                  ? t('createDentist.establishment.clinicMandatory', { ns: 'dentist' })
                  : undefined
              }
              variant={
                isShowClinicMandatoryMessage ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
              }
              className={styles['establishment-form__content__dropdown-address']}
              isSearchable={true}
            />
            <Text
              label={t('createDentist.establishment.or', { ns: 'dentist' })}
              className={styles['establishment-form__content__text']}
            />
            <Button
              data-cy="createClinic"
              category="outlined"
              label={creationButtonLabel()}
              onClick={() => setSideBarOpened(true)}
              className={styles['establishment-form__content__button--full-width']}></Button>
            <Checkbox
              data-cy="allowToOrder"
              label={t('createDentist.establishment.userAllowedToOrder', { ns: 'dentist' })}
              isChecked={isAllowedToOrder}
              onClick={() => {
                setIsAllowedToOrder(!isAllowedToOrder);
              }}
              className={styles['establishment-form__content__checkbox']}
            />
            {name && (
              <Box className={styles['establishment-form__content__address']}>
                <>
                  <Text label={name} data-cy="clinicName" />
                  <Text label={address} />
                  {additionalAddress && <Text label={additionalAddress} />}
                  {(zipCode || city || region) && (
                    <Text label={zipCode + ' ' + city + ' ' + region} />
                  )}
                  {country && (
                    <Text label={t(`countries.${country.toLowerCase()}`, { ns: 'common' })} />
                  )}
                </>
              </Box>
            )}
          </div>
          <Text
            label={t('createDentist.establishment.userWillReceiveInvite', { ns: 'dentist' })}
            size="s"
            color={ColorPropsEnum.GREY}
            className={[
              styles['establishment-form__content__text'],
              styles['establishment-form__footer']
            ].join(' ')}
          />
        </Fieldset>
        <div className="form__submit-button form__submit-button--double">
          <Button
            label={t('action.previous', { ns: 'common' })}
            category="outlined"
            onClick={handlePreviousButton}
            iconLeft="fa-chevron-left"
          />
          <Button
            label={t('createDentist.establishment.createDentist', { ns: 'dentist' })}
            type="submit"
            variant={ColorPropsEnum.SUCCESS}
            iconLeft="fa-check"
            data-cy="submitNewDentist"
            isLoading={isLoadingCreateClinic || isLoadingCreateDentist || isLoadingConnectedUser}
          />
        </div>
      </form>
      <SideBarModal
        title={creationButtonLabel()}
        isOpened={sideBarOpened}
        closeOnOutsideClick={true}
        onClose={() => setSideBarOpened(false)}>
        <CreateClinicForm
          establishment={newClinic}
          setSideBarOpenedCallback={handleCloseSidebar}
          isNewClinicCreated={isNewClinicCreated()}
        />
      </SideBarModal>
    </>
  );
};

export default EstablishmentForm;
