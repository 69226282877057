import { Box, Button, RadioList, NumberInput } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { DentalNotation, Language } from '../../../../../enum/user';
import { DentistInfos } from '../../../../../models/user';
import styles from '../dentist-detail-page.module.scss';
import useForm from '../../../../../utils/useForm';
import { dentistSelector } from '../../../../../store/users/users.selectors';
import { usersActions } from '../../../../../store/users/users.reducer';
import { dentalNotations, languages } from '../../../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { DeliveryTimeEnum } from '../../../../../enum/order.ts';

type Props = {
  saveCallback: (user: Partial<DentistInfos>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const CustomizationForm = ({ saveCallback, closeSideNavCallback }: Props) => {
  const { t } = useTranslation(['dentist', 'common']);
  const dispatch = useAppDispatch();
  const user = useAppSelector(dentistSelector);

  const saveCustomizationInfo = () => {
    dispatch(
      usersActions.setDentist({
        ...user,
        ...values,
        deliveryTime: parseInt(values.deliveryTime as string)
      })
    );
    if (user?.email)
      saveCallback(
        {
          ...values,
          deliveryTime: parseInt(values.deliveryTime as string)
        },
        user.email
      );
    closeSideNavCallback(false);
  };

  const onSubmit = () => {
    saveCustomizationInfo();
  };

  const { values, errors, handleSubmit, handleSelect, handleBlur, handleChange } = useForm(
    {
      dentalNotation: user?.dentalNotation ?? DentalNotation.ISO,
      language: user?.language ?? Language.fr_FR,
      deliveryTime: user?.deliveryTime ?? DeliveryTimeEnum.DEFAULT
    },
    onSubmit
  );

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form
        onSubmit={handleSubmit}
        className={styles['dentist-detail-page__content__sidebar__form']}>
        <RadioList
          title={t('createDentist.customization.dentalNotation')}
          options={dentalNotations}
          selectedValue={values?.dentalNotation}
          name="dentalNotation"
          onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
          className={styles['create-dentist-page__form__fieldset__input']}
        />
        <RadioList
          title={t('createDentist.customization.language')}
          options={languages}
          name="language"
          selectedValue={values?.language}
          onClick={(newValue: Language) => handleSelect(newValue, 'language')}
        />
        <NumberInput
          label={t('createDentist.customization.deliveryTime')}
          className={styles['create-dentist-page__form__fieldset__input']}
          id="deliveryTime"
          name="deliveryTime"
          value={values.deliveryTime}
          unitLabel={t('createDentist.customization.unitDeliveryLabel')}
          min={0}
          helperText={errors.deliveryTime && t('createDentist.customization.deliveryTimeError')}
          variant={errors.deliveryTime ? 'danger' : 'default'}
          radius="small"
          onBlur={handleBlur}
          onChange={handleChange}
          data-cy="delivery-delay-input"
        />
        <div className="form__submit-button form__submit-button--right">
          <Button label={t('action.update', { ns: 'common' })} type="submit" />
        </div>
      </form>
    </Box>
  );
};
export default CustomizationForm;
