import { useState } from 'react';
import {
  Box,
  BubblePicto,
  IconButton,
  SideBarModal,
  Text,
  Button,
  Dialog,
  Chips,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import { currentItemSelector, orderItemsSelector } from '../../../store/orders/orders.selectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import styles from './summary.module.scss';
import AddProductModal from '../prosthesis-form/add-product-modal/AddProductModal';
import { ordersActions } from '../../../store/orders/orders.reducer';
import { getLocalizedProperty } from '../../../utils/utils';
import { OrderItem, OrderItemLight } from '../../../models/order';
import { FullProduct, Product } from '../../../models/product';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { isCategoryProvisional } from '../utils';
import { mapActions } from '../../../store/map/map.reducer';
import {
  buildProductLabel,
  getTeethOrPartialTeethMaterial,
  getTeethShade,
  getToothOrPartialTooth
} from '../../../utils/order.utils';
import { getItemDentalArch } from '../../order-manager/teeth-map/utils';
import { OrderItemCard } from '../../order-item-card/OrderItemCard';
import { CardDisplayMode } from '../../../enum/product.enum';
import { TeethPositions } from '../../teeth-positions/TeethPositions';

type props = {
  canAddProduct: boolean;
  canDeleteItem: boolean;
};

export const Summary = ({ canAddProduct, canDeleteItem }: props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();

  const [sideBarOpened, setSideBarOpened] = useState<boolean>(false);
  const [isDialogDeleteItem, setIsDialogDeleteItem] = useState<boolean>(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>();
  const [selectedItemName, setSelectedItemName] = useState<string>();

  const currentItem = useAppSelector(currentItemSelector);
  const orderItems = useAppSelector(orderItemsSelector);

  const handleCloseSidebar = () => {
    setSideBarOpened(false);
  };

  const handleCancelSidebar = () => {
    setSideBarOpened(false);
    dispatch(ordersActions.setCurrentItem(undefined));
  };

  const openDeleteItemDialog = (index: number) => {
    setSelectedItemIndex(index);
    setSelectedItemName(
      orderItems[index].product[getLocalizedProperty('label') as keyof Product] as string
    );
    setIsDialogDeleteItem(true);
  };

  const deleteOrderItem = () => {
    dispatch(mapActions.removeProduct({ item: orderItems[selectedItemIndex!] as OrderItem }));
    const orderItemsCopy: (OrderItemLight | OrderItem)[] = Object.assign([], orderItems);
    orderItemsCopy.splice(selectedItemIndex!, 1);
    dispatch(ordersActions.setItems(orderItemsCopy));
    dispatch(mapActions.setZoneLinkPositions());
    setIsDialogDeleteItem(false);
  };

  return (
    <>
      <Dialog
        title={t('dialog.deleteItem.title', {
          product: selectedItemName
        })}
        text={t('dialog.deleteItem.text')}
        isOpened={isDialogDeleteItem}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('action.delete', { ns: 'common' })}
        onCancel={() => setIsDialogDeleteItem(false)}
        onConfirm={deleteOrderItem}
      />
      <SideBarModal
        title={t('createOrder.summary.addProduct')}
        isOpened={sideBarOpened}
        closeOnOutsideClick={false}
        onClose={() => setSideBarOpened(false)}>
        <AddProductModal
          onCloseCallback={handleCloseSidebar}
          onCancelCallback={handleCancelSidebar}
        />
      </SideBarModal>
      <Box color={ColorPropsEnum.WHITE} className={styles['summary']} padding={'spacing-0'}>
        <>
          {!currentItem && canAddProduct && (
            <Button
              className={styles['summary__button']}
              data-cy="add-product"
              iconRight="fa-plus"
              label={t('createOrder.summary.addProduct')}
              onClick={() => {
                setSideBarOpened(true);
              }}
            />
          )}
          {currentItem && (
            <Box className={styles['summary__current-product']}>
              <>
                <Text label={t('createOrder.summary.inProgress')}></Text>
                <div className={styles['summary__list__product']}>
                  <BubblePicto
                    size="small"
                    color={
                      ColorPropsEnum[
                        `FAMILY_${currentItem.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                      ]
                    }
                    isDashedBorder={
                      currentItem?.product?.category &&
                      isCategoryProvisional(currentItem.product.category)
                    }
                    url={currentItem.product.imageUrl}
                  />
                  <Text
                    label={currentItem.product[getLocalizedProperty('label') as keyof FullProduct]}
                    italic={true}></Text>
                  <IconButton
                    className={'summary__list__product__edit'}
                    color={ColorPropsEnum.PRIMARY}
                    iconSize={'sm'}
                    faIconClass="fa-pen-to-square"
                    onClick={() => {
                      setSideBarOpened(true);
                    }}
                    radius="full"
                  />
                </div>
              </>
            </Box>
          )}
          <ul className={styles['summary__list']} data-cy={'summary-item-list'}>
            {orderItems?.length > 0 &&
              orderItems.map((item: OrderItemLight | OrderItem, index) => {
                const toothComponent = getToothOrPartialTooth(item);
                const teethPositions = toothComponent?.teethPositions;
                const teethMaterial = getTeethOrPartialTeethMaterial(item);
                const teethShade = getTeethShade(item);
                return (
                  <li
                    key={`${item.product.id}--${index}`}
                    className={styles['summary__list__product']}>
                    <Tooltip isClickable variant="white">
                      <BubblePicto
                        isClickable={true}
                        size="small"
                        color={
                          ColorPropsEnum[
                            `FAMILY_${item.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                          ]
                        }
                        url={item.product.imageUrl}
                        isDashedBorder={
                          item?.product?.category && isCategoryProvisional(item.product.category)
                        }
                        className={styles['summary__list__product__bubble']}
                      />
                      <TooltipContent>
                        <OrderItemCard
                          item={item as OrderItem}
                          displayMode={CardDisplayMode.TOOLTIP}
                        />
                      </TooltipContent>
                    </Tooltip>
                    <Text
                      size="s"
                      label={buildProductLabel(
                        item.product.family,
                        (item.product as FullProduct)[
                          getLocalizedProperty('label') as keyof FullProduct
                        ] as string,
                        getItemDentalArch(item as OrderItem),
                        false
                      )}
                      data-cy="summary-product-label"
                    />
                    {teethPositions && <TeethPositions teethPositions={teethPositions} />}
                    {teethMaterial && (
                      <Chips
                        firstLabel={t(`material.${teethMaterial}`, {
                          ns: 'component'
                        })}
                        color={
                          ColorPropsEnum[teethMaterial.toUpperCase() as keyof typeof ColorPropsEnum]
                        }
                        size={'s'}
                      />
                    )}
                    {teethShade && (
                      <Chips
                        className={styles['print-product-list__shade']}
                        firstLabel={t(`shade.${teethShade}`, { ns: 'component' })}
                        color={
                          ColorPropsEnum[teethShade?.toUpperCase() as keyof typeof ColorPropsEnum]
                        }
                        size={'s'}
                      />
                    )}

                    {canDeleteItem && (
                      <IconButton
                        iconSize="sm"
                        faIconClass="fa-xmark"
                        onClick={() => openDeleteItemDialog(index)}
                        radius="full"
                      />
                    )}
                  </li>
                );
              })}
          </ul>
        </>
      </Box>
    </>
  );
};
