import { useCallback, useEffect, useState } from 'react';
import styles from './dentist-page.module.scss';
import { buildDentistsFilters, columnsDataDentist } from './dentist';
import DatagridFeature from '../../../features/datagrid/DatagridFeature';
import { Button, Dialog, Tabs } from '@platform-storybook/circlestorybook';
import { TabsType } from '../../../models/navigation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usersActions } from '../../../store/users/users.reducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  useDeactivateDentistMutation,
  useLazyGetPaginatedDentistsQuery,
  usePatchDentistMutation,
  useResetPasswordMutation
} from '../../../services/users-api.services';
import { FilterReducer, LoadDataProps } from '../../../models/datagrid';
import { usersDatagridStateSelector } from '../../../store/datagrids-settings/datagrids-settings.selectors';
import { datagridSettingsActions } from '../../../store/datagrids-settings/datagrids-settings.reducers';
import { DentistInfos } from '../../../models/user';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { buildSort } from '../../../features/datagrid/columns';
import { ToastType } from '../../../enum/feedback';
import DatagridReload from '../../../features/datagrid/DatagridReload';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { TypeDataSource } from '@inovua/reactdatagrid-community/types/TypeDataSource';
import { getDentistManagementPermissionsSelector } from '../../../store/auth/permissions.selectors.tsx';

const DentistPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const connectedUserPermissions = useAppSelector(getDentistManagementPermissionsSelector);

  const [isDialogSetAuthorizationOrderOpened, setIsDialogSetAuthorizationOrderOpened] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<DentistInfos | undefined>(undefined);

  const [getDentists] = useLazyGetPaginatedDentistsQuery();
  const [patchDentist, { isLoading: isLoadingPatchDentist, isSuccess: isPatchedDentist }] =
    usePatchDentistMutation();
  const [
    deactivateDentist,
    { isLoading: isLoadingDeactivateDentist, isSuccess: isDeactivatedDentist }
  ] = useDeactivateDentistMutation();
  const [resetPassword, { isLoading: isLoadingResetPassword, isSuccess: isResetPassword }] =
    useResetPasswordMutation();
  const [isDialogDeactivateOpened, setIsDialogDeactivateOpened] = useState<boolean>(false);
  const [isDialogResetPasswordOpened, setIsDialogResetPasswordOpened] = useState<boolean>(false);
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [reloadDate, setReloadDate] = useState<Date>();

  const datagridSettings = useAppSelector(usersDatagridStateSelector);

  const handleEscKey = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsDialogDeactivateOpened(false);
      setIsDialogResetPasswordOpened(false);
    }
  }, []);

  useEffect(() => {
    dispatch(usersActions.resetDentist());
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [handleEscKey]);

  useEffect(() => {
    if (isDeactivatedDentist) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.dentistDeactivated', { ns: 'dentist' }),
          type: ToastType.SUCCESS
        })
      );
      setSelectedUser(undefined);
      setIsDialogDeactivateOpened(false);
    }
  }, [isDeactivatedDentist]);

  useEffect(() => {
    if (isResetPassword) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.mailSent', { ns: 'dentist' }),
          type: ToastType.SUCCESS
        })
      );
      setSelectedUser(undefined);
      setIsDialogResetPasswordOpened(false);
    }
  }, [isResetPassword]);

  useEffect(() => {
    if (isPatchedDentist) {
      dispatch(
        feedbackActions.setToast({
          message: t('toast.userAllowedToOrder', { ns: 'dentist' }),
          type: ToastType.SUCCESS
        })
      );
      setIsDialogSetAuthorizationOrderOpened(false);
    }
  }, [isPatchedDentist]);

  const tabs: TabsType[] = [
    {
      label: t('tabs.dentists', { ns: 'dentist' }),
      disabled: false,
      to: '/dentist'
    }
  ];

  const loadData = async ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = buildDentistsFilters(filterValue!);
    const sort = buildSort(sortInfo);

    try {
      const { data: dentists } = await getDentists({ page, limit, filters, sort });
      setReloadDate(new Date());
      return dentists;
    } catch {
      return { data: [], count: 0 };
    }
  };

  const dataSource = useCallback(loadData, [
    isLoadingPatchDentist,
    isLoadingResetPassword,
    isLoadingDeactivateDentist
  ]);

  useEffect(() => {
    if (isReloadNeeded) {
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded]);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(
      datagridSettingsActions.setDatagridFilters({
        datagrid: 'users',
        filters: newFilterValue as FilterReducer[]
      })
    );
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    dispatch(
      datagridSettingsActions.setDatagridSort({
        datagrid: 'users',
        sort: newSortInfo as TypeSingleSortInfo
      })
    );
  };

  const resetPasswordCallback = async (dentist: DentistInfos) => {
    setSelectedUser(dentist);
    setIsDialogResetPasswordOpened(true);
  };

  const reset = async () => {
    setIsDialogResetPasswordOpened(false);
    if (selectedUser?.email) {
      resetPassword(selectedUser.email);
    }
  };

  const deactivateCallback = (dentist: DentistInfos) => {
    setSelectedUser(dentist);
    setIsDialogDeactivateOpened(true);
  };

  const deactivate = async () => {
    if (selectedUser?.email) {
      deactivateDentist(selectedUser.email);
    }
  };

  const setAuthorizationOrderCallback = (user: DentistInfos) => {
    setSelectedUser(user);
    setIsDialogSetAuthorizationOrderOpened(true);
  };

  const allowUserToOrder = async () => {
    if (selectedUser) {
      patchDentist({ email: selectedUser.email, isAllowedToOrder: true });
    }
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetDatagridFilters({ datagrid: 'users' }));
  };

  return (
    <>
      <Dialog
        title={t('dialog.deactivate.title', {
          ns: 'dentist',
          user: `${selectedUser?.firstName} ${selectedUser?.lastName}`
        })}
        text={t('dialog.deactivate.text', { ns: 'dentist' })}
        isOpened={isDialogDeactivateOpened}
        isLoading={isLoadingDeactivateDentist}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('dialog.deactivate.action', { ns: 'dentist' })}
        onCancel={() => setIsDialogDeactivateOpened(false)}
        onConfirm={deactivate}
      />
      <Dialog
        title={t('dialog.resetPassword.title', {
          ns: 'dentist',
          user: `${selectedUser?.firstName} ${selectedUser?.lastName}`
        })}
        text={t('dialog.resetPassword.text', { ns: 'dentist', email: selectedUser?.email })}
        isOpened={isDialogResetPasswordOpened}
        isLoading={isLoadingResetPassword}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('dialog.resetPassword.action', { ns: 'dentist' })}
        onCancel={() => setIsDialogResetPasswordOpened(false)}
        onConfirm={reset}
      />
      <Dialog
        title={t('dialog.allowToOrder.title', {
          ns: 'dentist',
          user: `${selectedUser?.firstName} ${selectedUser?.lastName}`
        })}
        isLoading={isLoadingPatchDentist}
        text={t('dialog.allowToOrder.text', { ns: 'dentist' })}
        isOpened={isDialogSetAuthorizationOrderOpened}
        cancelButtonLabel={t('action.cancel', { ns: 'common' })}
        confirmButtonLabel={t('dialog.allowToOrder.action', { ns: 'dentist' })}
        onCancel={() => setIsDialogSetAuthorizationOrderOpened(false)}
        onConfirm={allowUserToOrder}
      />
      <div className={styles['dentist-page__tab']}>
        <Tabs tabs={tabs} id={'dentist'}></Tabs>
      </div>
      <div className={styles['dentist-page__fab']}>
        <Button
          category="text"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={removeFilters}
        />
        {connectedUserPermissions.canPerformDentistManagementActions && (
          <Button
            iconRight="plus"
            data-cy="createDentistButton"
            label={t('action.createDentist', { ns: 'dentist' })}
            onClick={() => {
              navigate('/dentist/create');
            }}
          />
        )}
        {reloadDate && (
          <DatagridReload reloadDate={reloadDate} setIsReloadNeeded={setIsReloadNeeded} />
        )}
      </div>
      <DatagridFeature
        style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
        key="dentists"
        dataSource={dataSource as TypeDataSource}
        filterValue={datagridSettings.filters}
        onFilterValueChange={onFilterValueChange}
        sortInfo={datagridSettings.sort}
        onSortInfoChange={onSortInfoChange}
        columns={columnsDataDentist(
          connectedUserPermissions,
          setAuthorizationOrderCallback,
          resetPasswordCallback,
          deactivateCallback
        )}
      />
    </>
  );
};

export default DentistPage;
