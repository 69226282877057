import i18next from 'i18next';
import { FilterProps, MenuEntry, TypeColWithNamePropertyPlatform } from '../../../models/datagrid';
import {
  Button,
  Chips,
  DropdownMenu,
  IconButton,
  Link,
  Text,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { DentistRole } from '../../../enum/user';
import { DentistInfos, UserInfo } from '../../../models/user';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { EstablishmentPayload } from '../../../enum/establishment';
import moment from 'moment/moment';
import { getUrlEncodedFilter } from '../../../features/datagrid/columns';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { getColumnOptions } from '../../../features/datagrid/datagrid.utils';
import { DentistManagementPermissions } from '../../../models/connected-user.tsx';

const buildMenu = (
  connectedUserPermissions: DentistManagementPermissions,
  dentist: DentistInfos,
  setAuthorizationOrderCallback: (dentist: DentistInfos) => void,
  resetPasswordCallback: (dentist: DentistInfos) => void,
  deactivateCallback: (dentist: DentistInfos) => void
): MenuEntry[][] => {
  const allowedUserActions = [];

  if (connectedUserPermissions.canViewDentistInfo) {
    allowedUserActions.push({
      label: i18next.t('datagrid.menu.detail', { ns: 'dentist' }),
      type: 'nav',
      link: `/dentist/detail/${dentist.email}`
    });
  }

  if (connectedUserPermissions.canPerformDentistManagementActions) {
    allowedUserActions.push(
      ...[
        {
          label: i18next.t('datagrid.menu.allowToOrder', { ns: 'dentist' }),
          onClick: () => {
            setAuthorizationOrderCallback(dentist);
          },
          type: 'button',
          disabled: dentist.isAllowedToOrder || dentist.deletedAt !== undefined
        },
        {
          label: i18next.t('datagrid.menu.resetPassword', { ns: 'dentist' }),
          onClick: () => {
            resetPasswordCallback(dentist);
          },
          type: 'button',
          disabled: dentist.deletedAt !== undefined
        },
        {
          label: i18next.t('datagrid.menu.deactivate', { ns: 'dentist' }),
          onClick: () => {
            deactivateCallback(dentist);
          },
          type: 'button',
          disabled: dentist.deletedAt !== undefined
        }
      ]
    );
  }

  return [allowedUserActions];
};

export const columnsDataDentist = (
  connectedUserPermissions: DentistManagementPermissions,
  setAuthorizationOrderCallback: (dentist: DentistInfos) => void,
  resetPasswordCallback: (dentist: DentistInfos) => void,
  deactivateCallback: (dentist: DentistInfos) => void
): TypeColWithNamePropertyPlatform[] => {
  return [
    {
      ...getColumnOptions(
        'deletedAt',
        i18next.t('datagrid.columns.status', { ns: 'dentist' }),
        1,
        false
      ),
      showColumnMenuTool: false,
      maxWidth: 75,
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: [
          { id: 'active', label: i18next.t('active', { ns: 'common' }) },
          { id: 'inactive', label: i18next.t('inactive', { ns: 'common' }) }
        ]
      },
      render: ({ value }) => {
        let chips = (
          <Chips
            firstLabel={i18next.t(value ? 'inactive' : 'active', { ns: 'common' })}
            color={value ? ColorPropsEnum.DANGER : ColorPropsEnum.SUCCESS}
          />
        );
        if (value) {
          chips = (
            <Tooltip>
              <TooltipContent>
                {moment(value).format(i18next.t('date.full', { ns: 'common' }))}
              </TooltipContent>
              {chips}
            </Tooltip>
          );
        }
        return chips;
      }
    },
    {
      ...getColumnOptions('lastName', i18next.t('datagrid.columns.lastName', { ns: 'dentist' }), 1),
      showColumnMenuTool: false,
      render: ({ value, data }) => {
        return <Link href={`/dentist/detail/${data.email}`} label={value}></Link>;
      }
    },
    {
      ...getColumnOptions(
        'firstName',
        i18next.t('datagrid.columns.firstName', { ns: 'dentist' }),
        1
      ),
      showColumnMenuTool: false
    },
    {
      ...getColumnOptions('email', i18next.t('datagrid.columns.email', { ns: 'dentist' }), 1),
      showColumnMenuTool: false,
      render: ({ value }) => {
        return (
          <>
            <IconButton
              faIconClass="envelope"
              radius="full"
              onClick={() => {
                location.href = 'mailto:' + value;
              }}
            />
            {value}
          </>
        );
      }
    },
    {
      ...getColumnOptions('clinic', i18next.t('datagrid.columns.clinic', { ns: 'dentist' }), 1),
      showColumnMenuTool: false,
      render: ({ value }) => {
        return (
          <Tooltip>
            {value?.name}
            <TooltipContent>
              <div>
                <Text color={ColorPropsEnum.WHITE} label={value?.address?.address} />
                <Text
                  color={ColorPropsEnum.WHITE}
                  label={value?.address?.city + ' ' + value?.address?.zipCode}
                />
              </div>
            </TooltipContent>
          </Tooltip>
        );
      }
    },
    {
      ...getColumnOptions('role', i18next.t('datagrid.columns.role', { ns: 'dentist' }), 1),
      showColumnMenuTool: false,
      filterEditor: SelectFilter,
      filterEditorProps: {
        dataSource: [
          { id: DentistRole.DENTIST, label: i18next.t('user.roles.dentist', { ns: 'common' }) },
          { id: DentistRole.ASSISTANT, label: i18next.t('user.roles.assistant', { ns: 'common' }) }
        ]
      },
      render: ({ data }) => {
        const roleKey: keyof typeof DentistRole = data.role;
        const roleInEnum = Object.keys(DentistRole).includes(data.role)
          ? i18next.t(`user.roles.${DentistRole[roleKey].toLowerCase()}`, {
              ns: 'common'
            })
          : null;

        return <p>{roleInEnum || '-'}</p>;
      }
    },
    {
      ...getColumnOptions(
        'phoneNumber',
        i18next.t('datagrid.columns.phone', { ns: 'dentist' }),
        1,
        false
      ),
      showColumnMenuTool: false
    },
    {
      ...getColumnOptions(
        'isAllowedToOrder',
        i18next.t('datagrid.columns.orderNo', { ns: 'dentist' }),
        1,
        false
      ),
      showColumnMenuTool: false,
      render: ({ value, data }) => {
        return value ? (
          '15'
        ) : connectedUserPermissions.canPerformDentistManagementActions ? (
          <Tooltip>
            <TooltipContent>
              {i18next.t('datagrid.menu.allowToOrder', { ns: 'dentist' })}
            </TooltipContent>
            <Button
              label={i18next.t('datagrid.button.allowToOrder', { ns: 'dentist' })}
              size="s"
              category="outlined"
              onClick={() => setAuthorizationOrderCallback(data)}
              isDisabled={data.deletedAt}
            />
          </Tooltip>
        ) : (
          '0'
        );
      }
    },
    {
      ...getColumnOptions('menu', '', 1, false),
      showColumnMenuTool: false,
      maxWidth: 60,
      render: ({ data }) => {
        return (
          <DropdownMenu
            renderTargetButton={({ active }: { active: boolean }) => (
              <IconButton
                data-cy="datagrid-menu"
                faIconClass="ellipsis-vertical"
                isActive={active}
                radius="full"
              />
            )}
            data={buildMenu(
              connectedUserPermissions,
              data,
              setAuthorizationOrderCallback,
              resetPasswordCallback,
              deactivateCallback
            )}
          />
        );
      }
    }
  ];
};

export const buildDentistsFilters = (filterValue: Array<FilterProps>) => {
  let filters = '';
  filterValue
    .filter((filter) => filter.value)
    .forEach((filter) => {
      switch (filter.name) {
        case EstablishmentPayload.CLINIC:
          filters = `${filters}&filter.${filter.name}Name=$ilike:${encodeURIComponent(
            filter.value as string
          )}`;
          break;
        case 'role':
          filters = `${filters}&filter.${filter.name}=${filter.value}`;
          break;
        case 'deletedAt':
          filters = `${filters}&filter.deletedAt=${
            filter.value === 'active' ? '$null' : '$not:$null'
          }`;
          break;
        default:
          filters = `${filters}${getUrlEncodedFilter(filter)}`;
          break;
      }
    });
  if (!filters.includes('&filter.role')) {
    filters = `${filters}&filter.role=$in:${DentistRole.DENTIST},${DentistRole.ASSISTANT}`;
  }
  return filters;
};

export const mapDentists = (users: DentistInfos[]): DentistInfos[] => {
  return users?.map((user: DentistInfos) => mapDentist(user));
};

export const mapDentist = (user: DentistInfos): DentistInfos => {
  return {
    ...user,
    firstName: capitalizeFirstLetter(user.firstName),
    lastName: capitalizeFirstLetter(user.lastName)
  };
};

export const mapUser = (user: UserInfo): UserInfo => {
  return {
    ...user,
    firstName: capitalizeFirstLetter(user.firstName),
    lastName: capitalizeFirstLetter(user.lastName)
  };
};
