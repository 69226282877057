import styles from './step-actions.module.scss';
import { ManufacturingComponentView } from '../../../../../../models/component';
import i18next from 'i18next';
import { getActionNextStepLabel, isStepBackExists, allIsSuccess } from '../manufacturing-steps';
import {
  Text,
  Button,
  IconButton,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import moment from '../../../../../../moment-config';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { isInManufacturingPendingStatus } from '../../../../../../utils/order.utils.ts';

interface StepActionsProps {
  component: ManufacturingComponentView;
  callbackNextStep: (data: ManufacturingComponentView) => void;
  callbackPreviousStep: (data: ManufacturingComponentView) => void;
  isLoading: boolean;
}

const StepActions = ({
  component,
  callbackNextStep,
  callbackPreviousStep,
  isLoading
}: StepActionsProps) => {
  if (allIsSuccess(component.manufacturingStep)) {
    return (
      <div className={styles['step-actions__date']}>
        <Text
          color={ColorPropsEnum.GREY}
          label={moment(component?.manufacturingDate).format('DD MMM HH:mm')}
          size="s"
        />
        <IconButton
          data-cy={`previous-step-button-${component.id}`}
          className={!component.toManufacture ? styles['step-actions__actions--hidden'] : ''}
          faIconClass="backward"
          iconSize="sm"
          radius="full"
          onClick={() => callbackPreviousStep(component)}
          isDisabled={isLoading || isInManufacturingPendingStatus(component)}
        />
      </div>
    );
  } else {
    const displayedButton = (
      <Button
        data-cy={`handle-step-button-${component.manufacturingStep}`}
        label={getActionNextStepLabel(component)}
        size="s"
        isLoading={isLoading}
        onClick={() => callbackNextStep(component)}
      />
    );
    return (
      <>
        {isLoading ? (
          <Tooltip>
            <TooltipContent>
              {i18next.t('pendingElement.tooltipContent', { ns: 'common' })}
            </TooltipContent>
            {displayedButton}
          </Tooltip>
        ) : (
          displayedButton
        )}
        <Tooltip
          className={
            !isStepBackExists(component.manufacturingStep)
              ? styles['step-actions__actions--hidden']
              : ''
          }>
          <TooltipContent className={styles['step-actions__actions--hidden']}>
            {i18next.t('tooltip.backStep', { ns: 'production' })}
          </TooltipContent>
          <IconButton
            data-cy={`previous-step-button-${component.id}`}
            className={
              !isStepBackExists(component.manufacturingStep)
                ? styles['step-actions__actions--hidden']
                : ''
            }
            faIconClass="backward"
            iconSize="sm"
            radius="full"
            isDisabled={isLoading || isInManufacturingPendingStatus(component)}
            onClick={() => callbackPreviousStep(component)}
          />
        </Tooltip>
      </>
    );
  }
};

export default StepActions;
