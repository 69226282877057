import { api } from './api';
import { Establishment } from '../models/establishment';
import { EstablishmentPaginatedResponse } from '../models/datagrid';
import { EstablishmentType } from '../enum/establishment.ts';

export const establishmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllClinics: builder.query<EstablishmentPaginatedResponse, void>({
      query: () => `/establishments?limit=-1&filter.type=${EstablishmentType.CLINIC}`,
      providesTags: ['Clinics']
    }),
    getAllLaboratories: builder.query<EstablishmentPaginatedResponse, void>({
      query: () => `/establishments?limit=-1&filter.type=${EstablishmentType.LABORATORY}`,
      providesTags: ['Laboratories']
    }),
    createClinic: builder.mutation<Establishment, Establishment>({
      query: (payload: Establishment) => ({
        url: '/establishments',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Clinics']
    }),
    updateEstablishment: builder.mutation<Establishment, Establishment>({
      query: ({ id, ...patch }: Establishment) => ({
        url: `/establishments/${id}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['Laboratories', 'User']
    })
  })
});

export const {
  useGetAllClinicsQuery,
  useGetAllLaboratoriesQuery,
  useCreateClinicMutation,
  useUpdateEstablishmentMutation
} = establishmentsApi;
