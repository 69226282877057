import { useEffect, useState } from 'react';
import {
  Box,
  BubblePicto,
  Button,
  RadioList,
  Text,
  Toggle,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import styles from './add-product-modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ordersActions } from '../../../../store/orders/orders.reducer';
import {
  useGetAllProductsQuery,
  useLazyGetOneProductQuery
} from '../../../../services/products-api.services';
import { useGetCommonTypesQuery } from '../../../../services/common-types-api.services';
import { FullProduct, Product } from '../../../../models/product';
import {
  Family,
  ProductCategory,
  StumpMode,
  TeethSelectionMode
} from '../../../../enum/product.enum';
import {
  addProductStepIndexSelector,
  currentItemSelector,
  notCompatibleArchProductSelector,
  notCompatibleToothProductsSelector,
  orderItemsSelector,
  selectedCategoryValueSelector,
  selectedFamilyValueSelector
} from '../../../../store/orders/orders.selectors';
import { getLocalizedProperty, getMessageError } from '../../../../utils/utils';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import SkeletonList from '../../../skeleton-list/SkeletonList';
import ProductsManager, { MappedProducts } from '../../../order-manager/products-manager';
import { ToastType } from '../../../../enum/feedback';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import useForm from '../../../../utils/useForm';
import {
  areMiddleMaxillaryTeethInCurrentItem,
  buildItemComponentCustomizationValue,
  getCustomizationAttribute,
  getMappings
} from './utils';
import {
  ComponentType,
  ComponentTypeProperties,
  GingivaShadeEnum,
  MaterialEnum,
  ToothShadeEnum,
  ToothShapeEnum
} from '../../../../enum/component';
import {
  activeStructuresSelector,
  activeTeethSelector,
  diagnosticFromPositionsSelector
} from '../../../../store/map/map.selectors';
import { OrderItemComponentLight, OrderItemLight } from '../../../../models/order';
import { mapActions } from '../../../../store/map/map.reducer';
import { RadioListOptions } from '../../../../models/form';
import { StringObject, UnknownObject } from '../../../../models/common';
import CustomizationDropdown from './customization-dropdown.tsx/CustomizationDropdown';
import { isCategoryProvisional } from '../../utils';
import { productCustomizationActions } from '../../../../store/product-customization/productCustomization.reducer';
import CustomizationRadioList from './customization-radiolist.tsx/CustomizationRadioList';
import { DiagnosticCreation } from '../../../../models/patient.tsx';
import { Component } from '../../../../models/component.tsx';
import { PositionKey } from '../../../../models/position.tsx';
import { productCustomizationSelector } from '../../../../store/product-customization/productCustomization.selectors.tsx';

type Props = {
  onCancelCallback: () => void;
  onCloseCallback: () => void;
};

const AddProductModal = ({ onCloseCallback, onCancelCallback }: Props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();

  const orderItems = useAppSelector(orderItemsSelector);
  const diagnosticFromPositions = useAppSelector(diagnosticFromPositionsSelector);

  const {
    data: products,
    isSuccess: isSuccessGetProducts,
    isLoading: isLoadingGetProducts
  } = useGetAllProductsQuery();
  const {
    data: commonTypes,
    isLoading: areCommonTypesLoading,
    isSuccess: isSuccessGetCommonTypes
  } = useGetCommonTypesQuery();
  const [getOneProduct] = useLazyGetOneProductQuery();

  const currentItem = useAppSelector(currentItemSelector);
  const currentProduct = currentItem?.product;
  const addProductStepIndex = useAppSelector(addProductStepIndexSelector);
  const selectedFamilyValue = useAppSelector(selectedFamilyValueSelector);
  const selectedCategoryValue = useAppSelector(selectedCategoryValueSelector);
  const activeTeethItem = useAppSelector(activeTeethSelector);
  const activeStructuresItem = useAppSelector(activeStructuresSelector);
  const notCompatibleToothProducts = useAppSelector(notCompatibleToothProductsSelector);
  const notCompatibleArchProduct = useAppSelector(notCompatibleArchProductSelector);
  const activeCustomizations = useAppSelector(productCustomizationSelector);

  const [mappedProducts, setMappedProducts] = useState<MappedProducts | undefined>({});
  const [families, setFamilies] = useState<Family[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [productsManager] = useState<ProductsManager>(() => new ProductsManager());

  const familiesObjectEnum: { [key in keyof typeof Family]: Family } | undefined =
    commonTypes?.families;
  const categoriesObjectEnum:
    | { [key in keyof typeof ProductCategory]: ProductCategory }
    | undefined = commonTypes?.productCategories;

  const handleClickFamilyRadio = (value: Family): void => {
    dispatch(ordersActions.setSelectedFamilyValue(value));
    dispatch(
      ordersActions.setSelectedCategoryValue(
        productsManager.getFirstCategoryFromFamilyKey(value) as ProductCategory
      )
    );
  };

  const handleClickCategoryRadio = (value: ProductCategory): void => {
    dispatch(ordersActions.setSelectedCategoryValue(value));
  };

  if (isSuccessGetProducts && isSuccessGetCommonTypes && !families.length && !categories.length) {
    setFamilies(familiesObjectEnum ? Object.values(familiesObjectEnum) : []);
    setCategories(categoriesObjectEnum ? Object.values(categoriesObjectEnum) : []);
  }

  useEffect((): void => {
    if (families.length && categories.length && products?.data && products.data.length > 0) {
      productsManager.init(families, categories, products.data);
      setMappedProducts(productsManager.mappedProducts);
      if (!selectedFamilyValue) {
        dispatch(ordersActions.setSelectedFamilyValue(productsManager.firstFamilyKey as Family));
      }

      if (!selectedCategoryValue && productsManager.firstCategoryKey) {
        dispatch(ordersActions.setSelectedCategoryValue(productsManager.firstCategoryKey));
      }
    }
  }, [categories, families]);

  const optionsFamilyRadioButton = (): Array<RadioListOptions> => {
    if (familiesObjectEnum && Object.values(familiesObjectEnum).length) {
      return Object.values(familiesObjectEnum)
        .map((family) => {
          if (mappedProducts && Object.keys(mappedProducts).includes(family)) {
            return {
              label: t(`families.${family}`, { ns: 'catalog' }),
              value: family
            };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);
    } else {
      return [];
    }
  };

  const optionsCategoryRadioButton = (): Array<RadioListOptions> => {
    if (
      mappedProducts &&
      selectedFamilyValue &&
      selectedFamilyValue in mappedProducts &&
      Object.values(mappedProducts[selectedFamilyValue] as Product[]).length &&
      categoriesObjectEnum
    ) {
      return Object.values(categoriesObjectEnum)
        ?.map((category) => {
          if (Object.keys(mappedProducts[selectedFamilyValue] as Product[]).includes(category)) {
            return {
              label: t(`categories.${category}`, { ns: 'catalog' }),
              value: category
            };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);
    }
    return [];
  };

  const resetStates = (): void => {
    resetValues();
    dispatch(mapActions.removeActiveProduct());
    dispatch(mapActions.resetSelectionTeeth());
    dispatch(productCustomizationActions.resetProductCustomization());
    dispatch(mapActions.resetMapContext());
    dispatch(ordersActions.setCurrentItem(undefined));
    dispatch(ordersActions.setAddProductStepIndex(1));
    dispatch(ordersActions.setSelectedFamilyValue(productsManager.firstFamilyKey as Family));
    dispatch(
      ordersActions.setSelectedCategoryValue(productsManager.firstCategoryKey as ProductCategory)
    );
    dispatch(ordersActions.setError(undefined));
  };

  const onProductBubbleClick = (product: Product): void => {
    resetStates();
    dispatch(ordersActions.setAddProductStepIndex(2));
    getOneProduct(product.id)
      .unwrap()
      .then((response) => {
        const itemComponents = response?.components
          ?.map((component) => component.componentType)
          .map((componentType) => ({ componentType }));
        dispatch(ordersActions.setCurrentItem({ product: response, itemComponents }));
      })
      .catch((error) => {
        dispatch(
          feedbackActions.setToast({
            message: getMessageError(error),
            type: ToastType.DANGER
          })
        );
      });
  };

  const canBeSubmitted = () => {
    // todo : this won't work with Inlay-Core but it will be handled when it's implemented
    if (activeTeethItem.length === 0) {
      dispatch(
        ordersActions.setError(
          t(`createOrder.prosthesis.${currentItem?.product.teethMode.toLowerCase()}`)
        )
      );
      return false;
    }

    // 1 - check min teeth
    const minTeeth = currentItem?.product.components?.find(
      (component) => component.componentType === ComponentType.TOOTH
    )?.rule.min;
    if (minTeeth && activeTeethItem.length < minTeeth) {
      dispatch(ordersActions.setError(t('createOrder.error.minTeeth', { count: minTeeth })));
      return false;
    }

    return currentItem?.itemComponents?.length !== 0;
  };

  const buildItemComponentsToSave = () => {
    const itemComponents: OrderItemComponentLight[] = [...currentItem!.itemComponents!];

    const stumpPositions =
      currentItem?.product?.stumpMode === StumpMode.ALWAYS
        ? (activeTeethItem as PositionKey[])
        : undefined;

    Object.keys(values).forEach((customizationKey) => {
      if (currentItem) {
        const mappings = getMappings(customizationKey); // components types, key name in product items, and key name to write
        if (mappings) {
          itemComponents.forEach((component, index) => {
            if (values[customizationKey] && component.componentType === mappings.componentType) {
              if (
                mappings.componentTypeProperty ===
                ComponentTypeProperties.TOOTH_STRATIFICATION_TECHNIQUES
              ) {
                itemComponents[index] = {
                  ...component,
                  ...{ [mappings.itemComponentProperty]: values[customizationKey]?.toString() }
                };
              } else {
                const customizationAttribute = getCustomizationAttribute(
                  currentItem.product,
                  mappings.componentType,
                  mappings.componentTypeProperty,
                  +values[customizationKey]
                );
                itemComponents[index] = {
                  ...component,
                  ...buildItemComponentCustomizationValue(
                    mappings.itemComponentProperty,
                    +values[customizationKey]!,
                    customizationAttribute?.code
                  )
                };
              }
            }
          });
        }
      }
    });

    return itemComponents
      ?.filter(
        (component) =>
          // Do not keep optional frame => frame with material === -1
          !(component.componentType === ComponentType.FRAME && component.material?.id === -1)
      )
      .map((component) => {
        return {
          ...component,
          teethPositions: activeTeethItem as PositionKey[],
          structures:
            component.componentType === ComponentType.TOOTH ? activeStructuresItem : undefined,
          stumpPositions,
          svgLayer: currentItem?.product?.components?.find(
            (comp) => comp.componentType === component.componentType
          )!.svgLayer
        };
      });
  };

  const isValueEmpty = (values: UnknownObject, valueKey: string, product: FullProduct): boolean => {
    const componentProperties = getMappings(valueKey);
    const component = product.components?.find(
      (component) => component.componentType === componentProperties?.componentType
    );
    if (valueKey === 'teethShape') {
      return (areMiddleMaxillaryTeethInCurrentItem(activeTeethItem) &&
        component?.[componentProperties?.componentTypeProperty as ComponentTypeProperties]
          ?.length &&
        !values[valueKey]) as boolean;
    }

    return (component?.[componentProperties?.componentTypeProperty as ComponentTypeProperties]
      ?.length && !values[valueKey]) as boolean;
  };

  const onSubmitCallback = (): void => {
    // 1 - some checks
    if (!canBeSubmitted()) {
      return;
    }

    if (currentItem) {
      // 2 - save data in map reducer
      const counter = orderItems.filter((orderItem) => {
        return orderItem?.product?.id === currentItem.product.id;
      }).length;
      dispatch(
        mapActions.commitProductToMap({
          productId: currentItem.product.id,
          uniqueProductId: currentItem.product.id + '_' + counter,
          teethMode: currentItem.product.teethMode
        })
      );
    }

    // 3 - save items and diagnostic in order reducer
    const itemToSave = { ...currentItem, itemComponents: buildItemComponentsToSave() };
    dispatch(ordersActions.pushItem(itemToSave as OrderItemLight));
    dispatch(ordersActions.setDiagnostic({ ...diagnosticFromPositions } as DiagnosticCreation));

    resetStates();
    onCloseCallback();
  };

  const handleCancelClick = (): void => {
    resetStates();
    onCancelCallback();
  };

  const handleShadeDropdownChange = (
    id: number,
    target: 'teethShade' | 'partialToothShade'
  ): void => {
    handleSelect(id, target);
    // dispatch shade on map
    const shade = commonTypes?.shades.find((shade) => shade.id === id)?.code;

    // TODO: link the map action to the customization state with a useEffect
    dispatch(mapActions.setActiveProductShade(shade as ToothShadeEnum));
    dispatch(productCustomizationActions.setShade(shade as ToothShadeEnum));
  };

  const handleToggleStructure = () => {
    dispatch(
      productCustomizationActions.setDisplayStructureTool(
        !activeCustomizations?.displayStructureTool
      )
    );
    dispatch(
      mapActions.setTeethSelectionMode(
        !activeCustomizations?.displayStructureTool
          ? TeethSelectionMode.STRUCTURE_TOOTH
          : currentItem!.product.teethMode
      )
    );
    dispatch(ordersActions.setError(undefined));
  };

  useEffect((): void => {
    if (currentProduct) {
      const componentTooth = currentProduct.components?.find(
        (component) => component.componentType === ComponentType.TOOTH
      );

      dispatch(
        mapActions.initMapContext({
          productId: currentProduct.id,
          teethSelectionMode: currentProduct.teethMode,
          teethComponentRule: componentTooth?.rule,
          productRule: currentProduct.productRule,
          isOneDesign: currentProduct.isOneDesign,
          productCompatibilities: {
            notCompatibleToothProducts: notCompatibleToothProducts,
            notCompatibleArchProduct: notCompatibleArchProduct
          }
        })
      );
      // Once the product has been selected, init selection tooth (ToothSelectionEnum) to get cursors
      dispatch(mapActions.initSelectionTooth());
    }
  }, [currentProduct]);

  const toothComponent = currentItem?.product?.components?.find(
    (component) => component.componentType === ComponentType.TOOTH
  );

  const partialToothComponent = currentItem?.product?.components?.find(
    (component) => component.componentType === ComponentType.PARTIAL_TOOTH
  );

  const frameComponent = currentItem?.product?.components?.find(
    (component) => component.componentType === ComponentType.FRAME
  );

  const gingivaComponent = currentItem?.product?.components?.find(
    (component) => component.componentType === ComponentType.GINGIVA
  );

  const { values, errors, handleSelect, handleSubmit, resetValues } = useForm(
    {
      teethShade: undefined,
      partialToothShade: undefined,
      teethShape: undefined,
      teethMaterial: undefined,
      gingivaShade: undefined,
      gingivaMaterial: undefined,
      gingivaAspect: undefined,
      frameMaterial: undefined,
      partialToothMaterial: undefined,
      toothStratificationTechnique: undefined
    },
    onSubmitCallback,
    () => {
      const newErrors: StringObject = {};

      // teethShape is mandatory only if areMiddleMaxillaryTeethInCurrentItem
      // otherwise, all customization data is mandatory
      Object.keys(values).forEach((key) => {
        if (currentItem?.product && isValueEmpty(values, key, currentItem.product)) {
          newErrors[key] = 'empty';
        }
      });
      return newErrors;
    }
  );

  return (
    <>
      {addProductStepIndex === 2 && (
        <Button
          label={t('createOrder.addProductModal.customization.back')}
          category="text"
          iconLeft="fa-chevron-left"
          size="s"
          onClick={() => dispatch(ordersActions.setAddProductStepIndex(1))}
        />
      )}
      <Box color={ColorPropsEnum.WHITE} className={styles['add-product-modal']}>
        <form onSubmit={handleSubmit} className={styles['add-product-modal__form']}>
          {addProductStepIndex === 1 && (
            <div className={styles['add-product-modal__form__step1']}>
              {isLoadingGetProducts ? (
                <SkeletonList
                  className={styles['add-product-modal__form__step1__skeleton']}
                  heightItem="30px"
                  widthItem="300px"
                  count={6}></SkeletonList>
              ) : (
                <>
                  {products?.data && products.data.length === 0 && (
                    <Text label={t('createOrder.addProductModal.product.empty')}></Text>
                  )}
                  {products?.data && products.data.length > 0 && (
                    <>
                      <Text
                        label={t('createOrder.addProductModal.product.title')}
                        size="s"
                        type="title"
                        className={styles['add-product-modal__form__step1__title']}></Text>
                      <div>
                        {!areCommonTypesLoading && (
                          <>
                            <RadioList
                              data-cy="radio-family"
                              title={t('createOrder.addProductModal.product.family')}
                              onClick={handleClickFamilyRadio}
                              options={optionsFamilyRadioButton()}
                              displayedInRow={true}
                              selectedValue={selectedFamilyValue}
                              className={styles['add-product-modal__form__step1__radio']}
                            />
                            {optionsCategoryRadioButton().length > 0 && (
                              <RadioList
                                data-cy="radio-category"
                                title={t('createOrder.addProductModal.product.category')}
                                onClick={handleClickCategoryRadio}
                                options={optionsCategoryRadioButton()}
                                displayedInRow={true}
                                selectedValue={selectedCategoryValue}
                                className={styles['add-product-modal__form__step1__radio']}
                              />
                            )}
                          </>
                        )}
                        <div className={styles['add-product-modal__form__step1__products']}>
                          <Text
                            label={t('createOrder.addProductModal.product.products')}
                            className={styles['add-product-modal__form__step1__products__label']}
                            size="s"></Text>
                          <div className={styles['add-product-modal__form__step1__products__list']}>
                            {mappedProducts &&
                              selectedFamilyValue &&
                              selectedCategoryValue &&
                              mappedProducts?.[selectedFamilyValue]?.[selectedCategoryValue]?.map(
                                (product) => (
                                  <div
                                    className={
                                      styles[
                                        'add-product-modal__form__step1__products__list__product'
                                      ]
                                    }
                                    key={product.id}>
                                    <BubblePicto
                                      isClickable
                                      isDashedBorder={isCategoryProvisional(product.category!)}
                                      onClick={() => onProductBubbleClick(product)}
                                      size="large"
                                      color={
                                        ColorPropsEnum[
                                          `FAMILY_${selectedFamilyValue.toUpperCase()}` as keyof typeof ColorPropsEnum
                                        ]
                                      }
                                      mainTitle={
                                        product[getLocalizedProperty('label') as keyof Product]
                                      }
                                      mainTitleSize="s"
                                      displaysBorder={product.id === currentItem?.product?.id}
                                      url={product.imageUrl}
                                      data-cy="product-bubble"
                                    />
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {addProductStepIndex === 2 && currentItem?.product?.category && (
            <div className={styles['add-product-modal__form__step2']}>
              <Text
                label={t('createOrder.addProductModal.customization.title')}
                size="s"
                type="title"
                className={styles['add-product-modal__form__step2__title']}></Text>

              <Box
                color={ColorPropsEnum.GREY}
                className={styles['add-product-modal__form__step2__product']}
                padding="spacing-2">
                {currentItem && (
                  <>
                    <BubblePicto
                      size="small"
                      isDashedBorder={isCategoryProvisional(currentItem.product.category)}
                      color={
                        ColorPropsEnum[
                          `FAMILY_${currentItem.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                        ]
                      }
                      url={currentItem.product.imageUrl}
                    />
                    <Text
                      label={currentItem.product[getLocalizedProperty('label') as keyof Product]}
                    />
                  </>
                )}
              </Box>
              {currentItem?.product && !areCommonTypesLoading && commonTypes && (
                <div className={styles['add-product-modal__form__step2__product__customization']}>
                  {toothComponent?.structures && toothComponent?.structures?.length > 0 && (
                    <Tooltip zIndex={activeTeethItem?.length ? -1 : undefined}>
                      <TooltipContent>
                        {!activeTeethItem?.length
                          ? t('createOrder.addProductModal.customization.teethStructure.disabled')
                          : ''}
                      </TooltipContent>
                      <div
                        className={
                          styles[
                            'add-product-modal__form__step2__product__customization__structure'
                          ]
                        }>
                        <div
                          className={
                            styles[
                              'add-product-modal__form__step2__product__customization__structure__toggle'
                            ]
                          }>
                          <Text
                            size="s"
                            label={t(
                              'createOrder.addProductModal.customization.teethStructure.display'
                            )}
                          />
                          <Toggle
                            areLabelsHidden={true}
                            id="toggle-teeth-structure-selection"
                            isChecked={!!activeCustomizations?.displayStructureTool}
                            name="toggle-teeth-structure-selection"
                            isDisabled={!activeTeethItem?.length}
                            onChangeCallback={handleToggleStructure}
                          />
                        </div>
                      </div>
                    </Tooltip>
                  )}
                  {frameComponent?.materials && frameComponent.materials.length > 0 && (
                    <CustomizationRadioList
                      data-cy="frameMaterial"
                      component={frameComponent}
                      customizationKey="frameMaterial"
                      customizationTarget={ComponentTypeProperties.MATERIALS}
                      onClick={(id: string) => {
                        handleSelect(id, 'frameMaterial');
                        // dispatch frame material on map
                        const material = commonTypes.materials.find(
                          (material) => material.id.toString() === id
                        )?.code;
                        dispatch(
                          mapActions.setActiveProductFrameMaterial(material as MaterialEnum)
                        );
                        dispatch(
                          productCustomizationActions.setFrameMaterial(material as MaterialEnum)
                        );
                      }}
                      selectedValue={values.frameMaterial as string | undefined}
                      handleSelect={handleSelect}
                    />
                  )}
                  {toothComponent?.shades &&
                    toothComponent.shades.length > 0 &&
                    currentItem?.product?.components && (
                      <CustomizationDropdown
                        component={
                          currentItem.product.components.find(
                            (component) => component.componentType === ComponentType.TOOTH
                          ) as Component
                        }
                        customizationTarget={ComponentTypeProperties.SHADES}
                        value={values.teethShade as string | undefined}
                        error={errors?.teethShade}
                        onChange={(id: number) => {
                          handleShadeDropdownChange(id, 'teethShade');
                        }}
                        customizationKey="teethShade"
                        handleSelect={handleSelect}
                      />
                    )}
                  {partialToothComponent?.shades &&
                    partialToothComponent.shades.length > 0 &&
                    currentItem?.product?.components && (
                      <CustomizationDropdown
                        component={
                          currentItem.product.components.find(
                            (component) => component.componentType === ComponentType.PARTIAL_TOOTH
                          ) as Component
                        }
                        customizationTarget={ComponentTypeProperties.SHADES}
                        value={values.partialToothShade as string | undefined}
                        error={errors?.partialToothShade}
                        onChange={(id: number) => {
                          handleShadeDropdownChange(id, 'partialToothShade');
                        }}
                        customizationKey="partialToothShade"
                        handleSelect={handleSelect}
                      />
                    )}
                  {toothComponent?.shapes &&
                    toothComponent.shapes.length > 0 &&
                    areMiddleMaxillaryTeethInCurrentItem(activeTeethItem) &&
                    currentItem?.product?.components && (
                      <CustomizationDropdown
                        component={
                          currentItem.product.components.find((component) =>
                            [ComponentType.TOOTH].includes(component.componentType)
                          ) as Component
                        }
                        customizationTarget={ComponentTypeProperties.SHAPES}
                        value={values.teethShape as string | undefined}
                        error={errors?.teethShape}
                        onChange={(id: number) => {
                          handleSelect(id, 'teethShape');
                          const shape = commonTypes.shapes.find((shape) => shape.id === id)?.code;
                          dispatch(mapActions.setActiveProductShape(shape as ToothShapeEnum));
                        }}
                        customizationKey="teethShape"
                        handleSelect={handleSelect}
                      />
                    )}
                  {toothComponent?.materials &&
                    toothComponent.materials.length > 0 &&
                    currentItem?.product?.components && (
                      <CustomizationDropdown
                        component={
                          currentItem.product.components.find(
                            (component) => component.componentType === ComponentType.TOOTH
                          ) as Component
                        }
                        customizationTarget={ComponentTypeProperties.MATERIALS}
                        value={values.teethMaterial as string | undefined}
                        error={errors?.teethMaterial}
                        customizationKey="teethMaterial"
                        onChange={(id: number) => {
                          handleSelect(id, 'teethMaterial');
                        }}
                        handleSelect={handleSelect}
                      />
                    )}
                  {partialToothComponent?.materials &&
                    partialToothComponent.materials.length > 0 &&
                    currentItem?.product?.components && (
                      <CustomizationDropdown
                        component={
                          currentItem.product.components.find(
                            (component) => component.componentType === ComponentType.PARTIAL_TOOTH
                          ) as Component
                        }
                        customizationTarget={ComponentTypeProperties.MATERIALS}
                        value={values.partialToothMaterial as string | undefined}
                        error={errors?.partialToothMaterial}
                        customizationKey="partialToothMaterial"
                        onChange={(id: number) => {
                          handleSelect(id, 'partialToothMaterial');
                        }}
                        handleSelect={handleSelect}
                      />
                    )}
                  {toothComponent?.toothStratificationTechniques &&
                    toothComponent.toothStratificationTechniques.length > 0 && (
                      <CustomizationRadioList
                        customizationKey="toothStratificationTechnique"
                        component={toothComponent}
                        customizationTarget={
                          ComponentTypeProperties.TOOTH_STRATIFICATION_TECHNIQUES
                        }
                        selectedValue={values.toothStratificationTechnique as string | undefined}
                        onClick={(id: string) => {
                          handleSelect(id, 'toothStratificationTechnique');
                        }}
                        handleSelect={handleSelect}
                      />
                    )}
                  {gingivaComponent?.materials &&
                    gingivaComponent.materials.length > 0 &&
                    currentItem?.product?.components && (
                      <CustomizationDropdown
                        component={
                          currentItem.product.components.find(
                            (component) => component.componentType === ComponentType.GINGIVA
                          ) as Component
                        }
                        customizationTarget={ComponentTypeProperties.MATERIALS}
                        value={values.gingivaMaterial as string | undefined}
                        error={errors?.gingivaMaterial}
                        customizationKey="gingivaMaterial"
                        onChange={(id: number) => {
                          handleSelect(id, 'gingivaMaterial');
                        }}
                        handleSelect={handleSelect}
                      />
                    )}
                  {gingivaComponent?.aspects && gingivaComponent.aspects.length > 0 && (
                    <CustomizationRadioList
                      customizationKey="gingivaAspect"
                      customizationTarget={ComponentTypeProperties.ASPECTS}
                      component={gingivaComponent}
                      selectedValue={values.gingivaAspect as string | undefined}
                      onClick={(id: string) => {
                        handleSelect(id, 'gingivaAspect');
                      }}
                      handleSelect={handleSelect}
                    />
                  )}
                  {gingivaComponent?.shades?.length &&
                    gingivaComponent.shades.length > 0 &&
                    currentItem?.product?.components && (
                      <CustomizationDropdown
                        component={
                          currentItem.product.components.find(
                            (component) => component.componentType === ComponentType.GINGIVA
                          ) as Component
                        }
                        customizationTarget={ComponentTypeProperties.SHADES}
                        value={values.gingivaShade as string | undefined}
                        error={errors?.gingivaShade}
                        onChange={(id: number) => {
                          handleSelect(id, 'gingivaShade');
                          // dispatch gingiva on map
                          const gingiva = commonTypes.shades.find(
                            (gingiva) => gingiva.id === id
                          )?.code;
                          dispatch(mapActions.setActiveProductGingiva(gingiva as GingivaShadeEnum));
                          dispatch(
                            productCustomizationActions.setGingivaShade(gingiva as GingivaShadeEnum)
                          );
                        }}
                        customizationKey="gingivaShade"
                        handleSelect={handleSelect}
                      />
                    )}
                </div>
              )}
            </div>
          )}

          <div className="form__submit-button form__submit-button--double">
            <Button
              label={t('action.cancel', { ns: 'common' })}
              category="outlined"
              iconLeft="fa-trash"
              data-cy="cancel-product-button"
              onClick={() => handleCancelClick()}
            />
            <Button
              data-cy="add-product-button"
              label={t('createOrder.addProductModal.customization.button')}
              type="submit"
              variant={ColorPropsEnum.SUCCESS}
              iconLeft="fa-check"
              isDisabled={!currentItem?.product}
            />
          </div>
        </form>
      </Box>
    </>
  );
};
export default AddProductModal;
