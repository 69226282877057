// This is temporary
// To Do: add a date format parameter (short / medium / long )
// handle locale
// separator should not be in parameters
// Use moment library

import i18next from 'i18next';
import { ErrorCode, ToastType } from '../enum/feedback';
import { EstablishmentType } from '../enum/establishment';
import i18n from '../i18n';
import {
  AdminRole,
  DentalNotation,
  DentistRole,
  DesignerRole,
  LabUserRole,
  Language
} from '../enum/user';
import { DentistInfos, LabUser } from '../models/user';
import { forceRefreshToken } from '../services/firebase.services.tsx';
import { feedbackActions } from '../store/feedback/feedback.reducer.tsx';
import { Dispatch } from 'react';
import { authActions } from '../store/auth/auth.reducers.tsx';

export const REFRESH_DELAY = 5000;

export const formatDate = (dateToFormat: Date, options?: Intl.DateTimeFormatOptions) => {
  options = options
    ? { ...options, hour12: false }
    : {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      };
  return dateToFormat.toLocaleString(i18n.language, options);
};

/* We specify this rule because we don't know the payload type, and we use explicitly error as object after.*/
/* So this is an emergency case, don't reproduce */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getMessageError = (error: any): string => {
  let message = i18next.t('error.generic', { ns: 'common' });
  if ('data' in error) {
    if (typeof error.data === 'object') {
      // Handle error from our backend
      if (Object.values(ErrorCode).includes(error.data.error)) {
        message = i18next.t(error.data.error, { ns: 'error' });
      } else if (error.status !== 500 && 'message' in error.data) {
        message = error.data.message;
      }
    }
  }

  return message;
};

export const capitalizeFirstLetter = (string: string | undefined) => {
  return string
    ? string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1)
    : string;
};

export const isClinic = (establishmentType: string): boolean => {
  return establishmentType?.toLowerCase() === EstablishmentType.CLINIC.toLowerCase();
};

export const getLocalizedProperty = (property: string): string => {
  switch (property) {
    case 'name':
    case 'label':
      // This adds the locale after the filter name (for example, label -> labelFr or labelEn)
      return `label${capitalizeFirstLetter(i18next.language)}`;
    case 'productLabel':
      return `productLabel${capitalizeFirstLetter(i18next.language)}`;
    case 'products':
      return `products${capitalizeFirstLetter(i18next.language)}`;
    default:
      return property;
  }
};

export const getNameFromEmail = (users: (DentistInfos | LabUser)[] | undefined, email: string) => {
  const user = users?.find((user: DentistInfos | LabUser) => user.email === email);
  return `${user?.firstName} ${user?.lastName}`;
};

export const getDeliveryTimeFromDentistEmail = (
  users: DentistInfos[] | undefined,
  email: string
): number | undefined => {
  const user = users?.find((user: DentistInfos) => user.email === email);
  return user?.deliveryTime;
};

/**
 * Check if lab user role.
 * @param role role to check
 * @return true if lab user role
 */
export function isLabUser(role: AdminRole | DentistRole | DesignerRole | LabUserRole): boolean {
  return role in LabUserRole;
}

export function isSupervisor(role: AdminRole | DentistRole | DesignerRole | LabUserRole): boolean {
  return role === LabUserRole.SUPERVISOR;
}

export const dentalNotations = Object.keys(DentalNotation).map((value) => {
  return {
    label: i18next.t(`createDentist.customization.${value.toLowerCase()}.label`, { ns: 'dentist' }),
    value: value,
    helperText: i18next.t(`createDentist.customization.${value.toLowerCase()}.helperText`, {
      ns: 'dentist'
    })
  };
});

export const languages = Object.keys(Language).map((value) => {
  return { label: i18next.t(`language.${value}`, { ns: 'common' }), value: value };
});

export const viteMode = (): string => {
  let mode = '';
  if (import.meta.env.MODE === 'development' && import.meta.env.VITE_GOOGLE_CLOUD_DEV) {
    mode = 'dev';
  } else if (import.meta.env.MODE === 'development') {
    mode = 'local';
  }
  return mode;
};

export const isOrdersApi = (urlStartPart: string): boolean => {
  return ['orders', 'common-types', 'components', 'products', 'labs'].includes(urlStartPart);
};

export const isUsersApi = (urlStartPart: string): boolean => {
  return ['users', 'establishments'].includes(urlStartPart);
};

export const isManuApi = (urlStartPart: string): boolean => {
  return ['manufacturing'].includes(urlStartPart);
};

export const publicImagesUrl = 'https://storage.googleapis.com/public-circle-dental/images/';

export const checkTokenValidity = (dispatch: Dispatch<any>) => {
  /**
   * Check if the current token is currently valid.
   * Otherwise, redirect the user to the login page.
   */
  forceRefreshToken().catch((err) => {
    if (err?.message.includes('auth/user-token-expired')) {
      dispatch(
        feedbackActions.setToast({
          message: i18next.t('auth/user-token-expired', { ns: 'error' }),
          type: ToastType.DANGER
        })
      );
    } else {
      dispatch(feedbackActions.setToast({ message: err.message, type: ToastType.DANGER }));
    }
  });
};

export const saveUserStateForTests = (dispatch: Dispatch<object>) => {
  const isAuthorized = sessionStorage.getItem('isAuthorizedForTests') !== 'false';
  const roleValue = sessionStorage.getItem('roleForTests');
  const role =
    roleValue && Object.values(LabUserRole).includes(roleValue as LabUserRole)
      ? (roleValue as LabUserRole)
      : LabUserRole.SUPERVISOR;
  dispatch(
    authActions.saveUserState({
      firebaseUser: '{}',
      isAuthorized,
      role
    })
  );
};
